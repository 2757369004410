.navbar_parent{
    width: 100%;
    height: 60px;
    margin-top:21px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.navbar_items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--section-width);
}
.logo{
    height: 60px;
    width: 60px;

}

.button_array{
    display: flex;
    gap: 26px;
    align-items: center;
}

.mobile_menu_parent_default{
    display: none;
    justify-content: center;
    align-items: center;
    height: 53.89px;
    width: 53.89px;
    background: linear-gradient(30.16deg, rgba(238, 29, 72, 0.05) 34.16%, rgba(255, 130, 58, 0.05) 112.52%);
    border-radius: 16.8429px;  
    z-index: 7;
    position: fixed;
    right:5%;
    cursor: pointer;
}


@media only screen and (max-width:1200px){
    .mobile_menu_parent_default{
        display: flex;
    }
    .mobile_menu_parent{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 53.89px;
        width: 53.89px;
        background: linear-gradient(30.16deg, rgba(238, 29, 72, 0.05) 34.16%, rgba(255, 130, 58, 0.05) 112.52%);
        border-radius: 16.8429px;  
        z-index: 71;
        position: fixed;
        right:5%;
        cursor: pointer;
    }
    
    
    .mobile_menu_bars_list{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 7.5%;
        height:90%;
        width: 90%;
    }
    
    .top_bar,
    .middle_bar,
    .bottom_bar,
    .top_bar_clicked,
    .middle_bar_clicked,
    .bottom_bar_clicked
    
    {
        width: 29.47px;
        height: 3.68px;
        background: var(--theme-main-gradient);
        border-radius: 3px;
        position: absolute;
        transition: var(--theme-transition);
    }
    .top_bar{
        margin-top:-25%;
    }
    .bottom_bar{
        margin-top:25%;
    }
    .top_bar_clicked{
        transform: rotateZ(45deg);
        transition: var(--theme-transition);
    }
    .middle_bar_clicked{
        opacity: 0;
        transform: scaleX(0);
        transition: var(--theme-transition);
    }
    .bottom_bar_clicked{
        transform: rotateZ(-45deg);
        transition: var(--theme-transition);
    }
    

}

.overlay_test{

}
@media only screen and (max-width:1200px){
    .navbar_parent{
       
        width: 90%;
    }
    .navbar_items{
        
    }
    .logo{
        height: 67.37142944335938px;
        width: 66.36084747314453px;
        left: 0px;
        top: 0px;
        border-radius: 0px;
          
    
    }
    
    .button_array{
        display: none;
    }

}