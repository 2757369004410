.subscription_section_parent{
    width: 100%;
    height: 1044px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.subscription_section_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* width:var(--section-width); */
    gap:85px;
}

.subscription_section_contents .texts_box{
    width: 404px;
}

.subscription_section_toggle_items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:25px;
}

.subscription_card_row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap:104px;
    height: 500px;
}

.subscription_card_row_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap:104px;
}


.subscription_card_row_mobile_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap:104px;
}
.modal{
    width:500px;
    height: 500px;
    background-color: black;
}
@media only screen and (min-width:480px){

.subscription_card_row_mobile_contents{
    display: none;
}


}
@media only screen and (max-width:480px){

    .subscription_section_parent{
        
        height: 480px;
    }
    
    .subscription_section_contents{
        gap: 17.19px;
    }
    
    .subscription_section_contents .texts_box{
        width: 291.53px;
    }
    

    .subscription_section_contents .texts_box .texts_header{
        font-size: 18.04px;
    }

    .subscription_section_toggle_items{

    }
    .subscription_card_row_mobile_contents{
        /* margin-top: 25%; */
        flex-direction: column;
        gap:35px;
       
    }

    .subscription_card_row{
        margin-top: 10%;
        flex-direction: column;
        gap:35px;
        height: auto;
        gap: auto;
    }
    .subscription_card_row_contents{
        display: none;
    }
}

.subscription_card_row_contents .subscription_card_parent:first-of-type{
opacity: 0!important;
}
.subscription_card_parent_hide{
    opacity: 0!important;
    }
/* .subscription_card_row_contents .subscription_card_parent:last-of-type{
    opacity: 0!important;
    } */
/* 
.subscription_card_row_contents .subscription_card_parent:nth-of-type(n+2){
    opacity: 0!important;
    } */