.list_logo{
    width: 59.1px;
    height: auto;
}
.footer_list_parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.footer_list_parent h1{
    
    font-family: 'LufgaSemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}
.footer_list_ul{

    text-align: left;
    font-size: var(--smallest-text-size);
    width: 152px;
    font-family: "LufgaRegular";
    color: var(--main);
    line-height: 18px;

    align-self: flex-start;  
    display: flex;
    flex-direction: column;
    gap: 7px; 
    height: 100%;
}
.footer_list_ul .list_item{
    cursor: pointer;
    transition: var(--theme-transition)
}
.footer_list_ul .list_item:hover{
    color: var(--accent-color-3);
}

@media only screen and (max-width:480px){

    .list_logo{
        width: 59.1px;
        height: auto;
    }
    .footer_list_parent {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    .footer_list_parent h1{
        
        font-size: 11.5905px;
    }
    .footer_list_ul{
    
        gap: 4px;
        font-size: 10.14px;
        width: auto;
    }
       

}