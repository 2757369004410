.main_image_container{
    
display: flex;
align-items: center;
justify-content: center;
border: 7.49767px solid #FFFFFF;
filter: drop-shadow(0px 4.68605px 32.8023px rgba(0, 0, 0, 0.05));
border-radius: 32.8023px;
width: 284px;
height: 403px;
object-fit: contain;
overflow: hidden;
background-color: black;
}

.main_image_container img{
    height: 106%;
}


@media only screen and (max-width:480px){

    .main_image_container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4.83597px solid #FFFFFF;
        filter: drop-shadow(0px 4.68605px 32.8023px rgba(0, 0, 0, 0.05));
        border-radius: 32.8023px;
        max-height: 259px;
        max-width: 183px;
        border-radius: 21.157352447509766px;
        object-fit: contain;
        overflow: hidden;
        background-color: black;
    }

    .main_image_container img{
        width: auto;
        height: 106%;
        }
}