.results_hero_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}
.results_hero_contents {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 28px;
    margin-top: 800px;
}
.results_top_items{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.results_header{

    width: 564px;
   

    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: center;
    max-width: 465px;   
}
.results_subtext{

    width: 564px;


    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--subheading-text-size);
    /* line-height: var(--regular-text-line-height); */
    color: var(--accent-color-2);
    width: 100%;
    text-align: center;
    max-width: 465px;   
}
.results_header span, .suggestions_header span{
    color: var(--accent-color-2); 
}

.results_hero_contents .lower_items{
    display: flex;
    justify-content:flex-start;
    height: 1200px;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 25px;
    margin-top: 35px;
}
.results_hero_contents .lower_items .results_button {
    opacity: .5;
}
.results_sugggestions_parent{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
}
.references_modal_default {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    background-color: white;
    padding: 15px;
    top: 0;
    width: 70vw;
    height: 100vh;
    z-index: 0;
}
.references_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    background-color: white;
    padding: 15px;
    top: 0;
    width: 70vw;
    height: 100vh;
    z-index:71;
}

.references_modal_top_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 150px;
    width: 100%;
    max-width: 700px;
}
.reference_link_container{
    width: 100%;
    max-width: 700px;
    border-radius: 3.86px;
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;
    transition: all 0.35s ease-in-out;
    height: 35px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
       
}

.reference_link_container:hover .reference_link {
    color: var(--accent-color-2); 
}
.reference_link{
    font-family: "LufgaRegular";
    color: var(--accent-color-1);
}

.references_modal_close_button{
    border: 2px solid var(--accent-color-2);
    padding: 15px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-self: flex-end;
    
}
.ref_button_icon{
    font-size: 25px;
}
.references_modal_close_button:hover{
    border: 2px solid var(--accent-color-3);
    padding: 15px;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
}

/* .suggestions_header{
    align-self: flex-start;
    width: 564px;
    height: 52px;
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: left;
    max-width: 465px;   
} */

@media only screen and (max-width:480px){

    .results_hero_contents{
        margin-top: 600px;
    }

    
    .results_subtext{

        width: 70vw;
   
    }
    .results_sugggestions_parent{
        display: flex;
        flex-direction: column;
    }

  
}