.texts_box{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    gap: var(--texts_box_gap);
    
    width: 429px;
    height: 85px;
   
}

.texts_header{
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--subheading-text-size);
    color: var(--accent-color-4);
    width: 100%;
    text-align: center;

}
.texts_subtext{
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);
    color: var(--accent-color-1);
    text-align: center;
}

.texts_subtext span{
    color: var(--accent-color-2);
}



@media only screen and (max-width:480px){

    

}