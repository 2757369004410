

.subscription_card_parent{
    height: 439.2547302246094px;
    width: 315.82073974609375px;
    left: 0px;
    top: 11.43408203125px;
    border-radius: 5.655212879180908px;    
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;   
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;



}
.subscription_card_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:80.6943654%;
    width:77.6676588%;
    gap:15px;
    z-index: 2;
}
.subscription_card_contents .top_items {
    width: 192.47px;
    /* height: 138.05px; */
    flex-direction: column;
    display: flex;
    gap: 10px;
}

.triangle_background{
    width: 20%;
    height: auto;
    z-index: -1;
    opacity: .25;
    position: absolute;
    pointer-events: none;
}

.card_heading{
    font-size: var(--heading-text-size); 
    font-family: "LufgaBlack";
    text-align: center;
}
.card_price{
    font-size: 32px; 
    font-family: "LufgaSemiBold";
    color: var(--accent-color-1);
    text-align: center;
}

.card_price span{
    font-size: 30.49px; 
    font-family: "LufgaSemiBold";
    color: var(--accent-color-4);
    text-align: center;
}


.plan_description{
    font-size: 14.29px; 
    font-family: "LufgaRegular";   
    color: var(--accent-color-4);
    text-align: center;
}

.subscription_card_contents .bottom_items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:25px;
}
.subscription_details_list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:34px;
}
.subscription_details_list li{
    
    font-size: 15.25px; 
    font-family: "LufgaRegular";
    color: var(--accent-color-4);
    text-align: center;
}

@media only screen and (max-width:480px){

    .subscription_card_parent{
        display: none;
    }
    
}