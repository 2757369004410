.answer_model_button{
    background-color: var(--accent-color-2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    border: 0px solid white;
    background: #EE1D48;
    box-shadow: 0px 0px 5.47521px rgba(238, 29, 72, 0.65);
    border-radius: 3.92704px;
    cursor: pointer;
    transition: var(--theme-transition);


}
.answer_model_button:hover{
    opacity: .7;
}
.answer_model_button_icon{
    font-size: 22px;
    transition: var(--theme-transition);
}


.answer_model_button_secondary{
    background-color: var(--accent-color-3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    border: 0px solid white;
  
    box-shadow: 0px 0px 5.47521px rgba(238, 29, 72, 0.65);
    border-radius: 3.92704px;
    cursor: pointer;
    transition: var(--theme-transition);


}
.answer_model_button_secondary:hover{
    opacity: .7;
}
.answer_model_button_secondary_icon{
    font-size: 22px;
    transform: rotatex(180deg);
    transition: var(--theme-transition);
}

