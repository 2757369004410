.tooltip_container{
    display: inline-block;
    display: none;
}

.tooltip_icon{
    cursor: pointer;
    color: var(--accent-color-2);
    
}

.tooltip_icon:hover{
    opacity: .5;
}

.tooltip_modal_hidden{
    z-index: 0;
}
.tooltip_modal{
    height: 200px;
    width: 200px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 65;
    transition: var(--theme-transition);
    border-radius: 15px;
    padding: 15px;
    gap: 35px;
}


.tooltip_modal_contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;


}
.tooltip_modal_contents .top_items{
    display: flex;
    flex-direction: column;
}
.tooltip_modal_contents h1{

    font-family: "LufgaSemiBold";
    font-weight: 400;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
}
.tooltip_modal_contents .top_items h2{

    font-family: "LufgaBlack";
    font-weight: 400;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-2);
}


.tooltip_modal_contents .bottom_items h2{

    font-family: "LufgaBlack";
    font-weight: 400;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-3);
}