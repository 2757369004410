.home_hero{
    height: var(--section-height) ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.home_hero_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--main-width);
    background-color: rgba(255, 230, 0, 0);
}
.home_hero_container{
    height:449px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--section-width) ;
    background-color: rgba(0, 255, 255, 0);
    

}

.home_hero_container .left_items{
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 70%;
    /* gap:15%; */
    gap: 45px;
}

.home_hero_container .left_items h1{
    color: var(--accent-color-1);
    font-size: var(--heading-text-size);
    line-height: 52.21px;
    z-index: 36;
    
}

.home_hero_container .left_items p{
    color: var(--accent-color-1);
    font-size: var(--larger-paragraph-size);
    font-family: "LufgaRegular";
    max-width: 429px;

    text-align: left;
    align-self: flex-start;
    line-height: 25px;
    
}
.buttons_container{
    display: flex;
    gap: 22px;
}
.demo_open_text{
    font-family: "LufgaRegular";
    color: var(--accent-color-3);
    position: absolute;
    margin-top: 220px;
    cursor: pointer;
}

.demo_open_text:hover{
    color: var(--accent-color-2);
}


.demo_open_text_mod{
    font-family: "LufgaRegular";
    color: var(--accent-color-3);
    position: absolute;
    margin-top: 180px;
    cursor: pointer;
}

.demo_open_text_mod:hover{
    color: var(--accent-color-2);
}











.arrow_text_overlay {
    position: absolute;
    margin-bottom: -195px;
    margin-left: 110px;
    z-index: 35;
}


.home_hero_container .right_items{
    width: 50%;
    height: 100%;
}

.images_container{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.images_container >*{
    position: absolute;
  

}
.images_container  > .main_image_container {
    z-index: 1;
    align-self: flex-start;
}
.images_container  > .main_image_container ~ .main_image_container {
    margin-left: 185px;

    z-index:0;
    align-self: flex-end;

}


.blur_circle_1{
   
    position: absolute;
    /* left: -19.1%;
    right: 75.84%;
    top: 1.1%;
    bottom: 86.28%; */
    right: 65%;
    opacity: 0.75;
    z-index: -1;

}

.blur_circle_2{
   
    position: absolute;
  
    right: 22%;
    opacity: 1;
    z-index: -1;

}

@media only screen and (max-width:480px){

    .home_hero{
  
       flex-direction: column;
       width: 90vw;
   
    }

    .home_hero_contents{
        height: 80%;
        margin-top: 70px;
    }
    .home_hero_container{
        height:100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:5%;
        position:relative;
    
    }
    
    .home_hero_container .left_items{
        width: 85%;
        min-height: 185.2714385986328px;
        max-height: 35%; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;    
        gap:15%;
    }
    
    .home_hero_container .left_items h1 {
        color: var(--accent-color-1);
        font-size: 25px;
        text-align: center;
        width: 85vw;
        max-width: 375px;
        line-height:normal;
    }
    
    .home_hero_container .left_items p {
        color: var(--accent-color-1);
        font-size: 16px;
        font-family: "LufgaRegular";
        min-width: 290px;
        width: 70vw;
        height: auto;
        text-align: center;
        align-self: center;
        line-height: 18px;
    }
    
    .arrow_text_overlay{
        position: absolute;
        display: none;
        z-index: 35;
    }
    
    .home_hero_container .right_items{
        position:relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    
    .images_container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5%;
    }
    .images_container >*{
        position: absolute;  
    }
    .images_container  > .main_image_container {
        z-index: 1;
        align-self: flex-start;
        margin-right: 10%;
    }
    .images_container  > .main_image_container ~ .main_image_container {
        z-index: 0;
        align-self: flex-start;
        margin-right: 5%;
        margin-top: 3%;
    }
    
    
    .blur_circle_1{   
        position: absolute;
        right: -32%;
        top:-35%;
        opacity: 0.75;
        z-index:-1;
    }
    
    .blur_circle_2{       
        position: absolute;      
        right: 22%;
        opacity: 1;
        z-index: -1;    
    }
    
}
