.stats_box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
    gap: var(--stats_box_gap);
    
    width: auto;
    /* max-width: var(--stats-box-max-width); */
   
}

.stats_number{
    font-family: "LufgaBold";
    font-weight: 700;
    font-size: var(--large-subheading-text-size);
    color: var(--accent-color-3);
}

.stats_text{
    font-family: "LufgaSemiBold";
    font-weight: 700;
    font-size: var(--small-text-size);
    color: var(--accent-color-4);
    width: 100%;
}

@media only screen and (max-width:480px){
    .stats_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--stats_box_gap);
        width: 80px;
        max-width: var(--stats-box-max-width);
    }
    .stats_number{
        text-align: center;
        font-size: 25.45px;
    }
    
    .stats_text{
        text-align: center;
        font-size: 10.9091px;
    }


}