.navbar_parent {
   position: relative;
}

.sign_in_page_parent{
   position: relative;
   width: 100vw;
   /* overflow-x: hidden; */
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.sign_in_page_parent {
   opacity: 1;
   transform: none;
   display: flex;
   flex-direction: column;
   height: 100vh;
   justify-content: flex-start;
}

.sign_in_parent{
   position: relative;
   width: 100vw;
   overflow-x: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   height: 100vh;
}
.sign_in_page_contents{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   position: relative;
   width: 100%;
   height: 100vh;
}

.sign_in_page_container{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 85px;
   position: relative;
   overflow-x: hidden;
   width: 100%;
   height: 100%;
   
}

.sign_in_page_contents .firebaseui-idp-password {
   background-color: var(--accent-color-2)!important;
   box-shadow: 0px 3.70948px 25.9664px rgba(238, 83, 83, 0.5);
   font-family: "LufgaRegular"!important;
   font-size: 25px!important;
   max-width: 350px!important;
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 21px 8px 12px;
   gap: 4px;

   border-radius: 8px;
   border: 0px solid white;
   color:white;
   font-family: "LufgaRegular";
   font-size: var(--smallest-text-size);
   cursor: pointer;
   transition: var(--theme-transition);



}

.firebaseui-idp-icon-wrapper {
   /* display: table-cell; */
   /* vertical-align: middle; */
   display: flex!important;
   align-items: center!important;
   justify-content: center!important;
   width: 25px!important;
}
.firebaseui-idp-icon {
   border: none;
   display: inline-block;
   /* height: 100%!important; */
   vertical-align: middle;
   /* width: auto; */

   height: 100%!important;
   width: 100%!important;
}
.firebaseui-idp-google{
   background-color: var(--accent-color-3)!important;
   box-shadow: 0px 3.70948px 25.9664px rgba(238, 83, 83, 0.5);
   font-family: "LufgaRegular"!important;
   font-size: 25px!important;
   max-width: 350px!important;
   display: flex!important;
   flex-direction: row;
   align-items: center;
   padding: 8px 21px 8px 12px!important;
   gap: 4px!important;
   /* Accent_Color_2 */
   background: var(--accent-color-1)!important;
   border-radius: 8px!important;
   border: 0px solid white;
   color:white;
   font-family: "LufgaRegular";
   font-size: var(--smallest-text-size)!important;
   cursor: pointer;
   transition: var(--theme-transition)!important;
}
.firebaseui-idp-google>.firebaseui-idp-text {
   color: white!important;
}

.firebaseui-idp-button:hover, .firebaseui-tenant-button:hover {
   opacity: .5 !important;
}
.firebaseui-idp-text{
   font-size: 25px!important;
}


.firebaseui-idp-button, .firebaseui-tenant-button {
   direction: ltr !important;
   font-weight: 500 !important;
   height: auto !important;
   line-height: normal !important;
   /* max-width: 220px; */
   min-height: 40px !important;
   padding: 8px 16px !important;
   text-align: left !important;
   width: 100% !important;
   display: flex !important;
   border-radius: 8px !important;
   font-family: "LufgaRegular" !important;
   transition: var(--theme-transition)!important;
   max-width: 100%!important;
}

.fr_navigation_hide{
   opacity: 1;
   display: flex!important;
   align-items: center!important;
   justify-content: center!important;
   flex-direction: column;
   gap: 50px;
}

.fr_navigation .loading_overlay_parent{
   z-index: 150;
}