.mobile_menu_overlay_parent{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color-1);
    z-index: 60;
    display: none;  /*change-for-mobile */
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile_menu_parent_hidden{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color-1);
    z-index: -100;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

.menu_list{
    display: flex;
    flex-direction: column;
    gap: 85px;
}

.menu_list li{
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--heading-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-4);
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: var(--theme-transition);
}

.menu_list li:hover{
    color: var(--accent-color-2) ;
}

@media only screen and (max-width: 1200px){
    .mobile_menu_overlay_parent{
        display: flex;

    }
    
    .mobile_menu_parent_hidden{

        display: none;
 
    }
}