.questions_section_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 696px;
    width: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
    
}
.questions_background_element{
    position: absolute;
    opacity: .25;
    z-index: -3;
}
.questions_section_parent .text_items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    gap:30px;
}

.questions_section_parent h1{
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--subheading-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-4);
    
}



.questions_section_parent h2{
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    
}


.questions_section_parent h2 span{
    color: var(--accent-color-2);
}


@media only screen and (max-width:480px){
    .questions_section_parent{
        height: 460px;       
    }
    .questions_background_element{

    }
    .questions_section_parent .text_items{

    }
    
    .questions_section_parent h1{
 
    }
    
    
    
    .questions_section_parent h2{
    
        
    }
    
    
    .questions_section_parent h2 span{
     
    }
    
    

}