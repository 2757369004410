.subscription_card_variant_parent{
    position: relative;
    transform: scale(1.04);
    z-index: 2;
}

.background_shape_top_left{
    left: -6.34%;
    right: 30.61%;
    top: -5%;
    bottom: 44.82%;
    z-index: -1;
    position: absolute;
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
}



.background_shape_bottom_right{
    left: 25%;
    right: -5.61%;
    top: 50%;
    bottom: -5%;
    z-index: -1;
    position: absolute;
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
}