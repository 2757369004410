.tbws_parent{
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
    flex-direction: column;
}

.tbws_parent >.texts_box {
    z-index: 1;
    align-self: flex-start;
    opacity: 1;
}

.tbws_parent >.texts_box ~ svg {
    z-index: 1;
    align-self: flex-end;
    opacity: 1;
    margin-top: -2%;
}


@media only screen and (max-width:480px){

    
.tbws_parent >.texts_box ~ svg {
    z-index: 1;
    align-self: flex-end;
    opacity: 1;
    margin-top: -5%;
}

}