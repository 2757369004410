.decision_search_box_parent{
    height: 109px;
    /* width: 541px; */

    display: flex;
    filter: drop-shadow(0px 4px 75px rgba(238, 29, 72, 0.25));
    z-index: 52;
}


.decision_search_box_shape_tl{
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 49px;
    width: 146px;
    margin-left: -20px;
    margin-top: -25px;
   
    border-radius: 5.935173988342285px;
    position: absolute;

}

.decision_search_box_shape_br{
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 49px;
    width: 146px;
    margin-left: 385px;
    margin-top: 45px;
   
    border-radius: 5.935173988342285px;
    position: absolute; 
}
.decision_search_box_background{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 79px;
    width: 498px;
    background: #FFF8F8;
    box-shadow: 0px 7.41071px 51.875px rgba(0, 0, 0, 0.05);
    border-radius: 0px 22.2321px;
    z-index: 1;
    
}

.decision_search_box_foreground{

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;
    height: 56.639px;
    width: 470.39px;
    left: 30px;
    top: 32px;
    border-radius: [object Object]px;
    
    
    background: #FFFFFF;
    box-shadow: 0px 4.92689px 34.4883px rgba(0, 0, 0, 0.05);
    border-radius: 0px 14.7807px;

}
.decision_search_box_foreground_contents{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:92%;
}
.decision_search_input{
    width: 70%;
    font-family: 'Lufga';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    border: 0px solid white;
    color: #EE1D48;
    
}

/* To remove black border on focus */
.decision_search_input:focus {
    border-style: none; 
    border-color: Transparent; 
    overflow: auto;  
    outline: none;
}

@media only screen and (max-width:480px){
    .decision_search_box_parent{
        height: 109px;
        /* width: 541px; */
    
        display: flex;
        filter: drop-shadow(0px 4px 75px rgba(238, 29, 72, 0.25));
    }
    
    
    .decision_search_box_shape_tl{
        background: #EE1D48;
        box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
        border-radius: 5.93517px;
        height: 28.93px;
        width: 86.20px;
        border-radius: 3.50px;
        margin-left:-5%;
        margin-top: -2%;
        position: absolute;
    
    }
    
    .decision_search_box_shape_br{
        background: #EE1D48;
        box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
        border-radius: 5.93517px;
        height: 28.93px;
        width: 86.20px;
        border-radius: 3.50px;
        margin-left: 75%;
        margin-top: 10%;
       
  
        position: absolute; 
    }
    .decision_search_box_background{
        height: 46.64px;
        width: 294.03px;
        left: 9.44px;
        top: 11.80px;
        border-radius: 0px 13px 0px 13px;
        
        
    }
    
    .decision_search_box_foreground{
    
        height: 33.44170379638672px;
        width: 277.7361755371094px;
        left: 17.712646484375px;
        top: 18.89361572265625px;
        border-radius:  0px 8.73px 0px 8.73px;
        
    
    }
    .decision_search_box_foreground_contents{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width:92%;
    }
    .decision_search_input{
        width: 70%;
        font-family: 'Lufga';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        display: flex;
        align-items: center;
        border: 0px solid white;
        color: #EE1D48;
        
    }

}