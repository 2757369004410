.stats_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height: var(--stats-height);
    background-color: var(--theme-color-1)
}

.stats_contents{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:var(--section-width);
    height: var(--stats-height);
    background-color: var(--theme-color-1)
}
.stats_main_text{
    font-family: "LufgaBlack";
    font-size: var(--subheading-text-size);
    color: var( --accent-color-1)   
}
.stats_box_array{
    display:flex;
    gap: 3.813rem;
}

@media only screen and (max-width:480px){
    .stats_parent{
        height: 154px;
        
        

    }
    
    .stats_contents{
        flex-direction: column;
        height: 104px;
        justify-content: center;
        gap: 24px;
  
    }
    .stats_main_text{
   
    }
    .stats_box_array{
        
    }
    
}