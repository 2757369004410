.banner_parent{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: var( --banner-section-height);
    background: linear-gradient(92.06deg, #EE1D48 8.41%, #FF823A 69.85%);   
}

.banner_contents{
    width:var(--section-width);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.banner_contents .left_items{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:var(--regular-text-size);
}
.email_header{
    font-family: "LufgaSemiBold";
    font-weight: 700;
    font-size: var(--heading-text-size);
    color: var(--main);
    width: 100%;
    text-align: left;
    width: 429px;
    height: 104px;
    line-height: 52px;
    
}

.banner_contents .right_items{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-end;
}

.banner_contents .right_items .right_image{
    width: 783px;
    height: 521.8px;
    pointer-events: none;
}

@media only screen and (max-width:480px){
    .banner_parent{
        display: none;
    }
}