.single_plan_hero_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    width: 100vw;
}
.single_plan_hero_contents{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap: 28px;
    width: var(--section-width);
    height: 100%;

}


.plan_details_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; 
    gap: 8px;
   
    
}

.plan_details_container_left_items{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 50%;
    height: 100%;
   
}

.plan_details_container_left_items .plan_list_header{
    font-style: normal;

    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 400;
    font-size: var(--larger-paragraph-size);
    line-height: 45px;
    color: var(--accent-color-1);
}

.single_plan_hero_contents .left_items .button_parent_container{
    margin-top: 39px;

}
.plan_details_container_left_items .plan_list_header span{
    color: var(--accent-color-2); 
    font-family: "LufgaBlack";
}


.plan_details_container_left_items .plan_list_header .secondary_span{
    color: var(--accent-color-3); 
    font-family: "LufgaBlack";
}
.secure_checkout_details_container{
    margin-top: 39px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    font-family: "LufgaBlack";
    color: var(--accent-color-4); 
}
.plan_details_container_right_items{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.plan_details_container_right_items .plan_list_subtext{
    font-style: normal;
    gap:21px;
    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 400;
    font-size: var(--larger-paragraph-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    display: flex;
    flex-direction: column;
    gap: 42px;
    max-width: 365px;
    line-height: 25px;
}


.single_plan_header{

    width: 564px;
    /* height: 52px; */
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);
    /* line-height: var(--regular-text-line-height); */
    line-height: 55px;
    color: var(--accent-color-1);
    width: 100%;
    text-align: left;
    max-width: 465px;   
}

.single_plan_header span, .suggestions_header span{
    color: var(--accent-color-2); 
}

.plan_details_container .right_items {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 50%;
    height: 100%;
}
.single_plan_sugggestions_parent{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
}

.suggestions_header{
    align-self: flex-start;
    width: 564px;
    height: 52px;
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: left;
    max-width: 465px;   
}

@media only screen and (max-width:480px){
    .single_plan_hero_contents {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 28px;
        margin-top: 0vh;
    }
    .single_plan_sugggestions_parent{
        display: flex;
        flex-direction: column;
    }

    .secure_checkout_details_container{
        align-items: center;
        justify-content: center;
    }
    .single_plan_hero_contents .left_items {
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       
    
    }   
.single_plan_header{
 
    width: 361px;
    height: 105px;


    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 900;
    font-size: 32px;
    line-height: 42px;
    text-align: center;

    /* Accent_Color_1 */

    color: #0B1945;
}
    .plan_details_contanier{
        display: flex;
        align-items: center;
        justify-content: center;     
    }

    .plan_list_subtext{
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px!important;
        line-height: 21px!important;
    }
}