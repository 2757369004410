.video_player_container{
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 40px 39px;
    gap: 10px;

    max-width: 505.45px;
    height: 343.45px;



    background: #FFF7EA;


    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    }

.video_background{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 95%;
 
    background-color: black;
  
    gap: 10px;


    /* Theme_Shadow_1 */
    
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05);
    border-radius: 25px;


}

.play_button_icon{
    position: absolute;
    color: var(--accent-color-2);
    width: 99.45px;
    height: 99.45px;
    cursor: pointer;

}

.video_preview_image{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
}
@media only screen and (max-width:480px){

    .video_player_container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 39px;
        gap: 10px;
        position: absolute;
        width: 70vw;
        height: 50vw;
        background: #FFF7EA;
        box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        max-width: 80%;
        /* max-height: 30%; */
    }

}