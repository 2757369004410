.home_subheading_parent{
    height: var(--subheading-section-height);
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.subheading_arrow{
    position: absolute;
    z-index: 0;
    margin-top: -65px;
    margin-right: 15px;
    transform: scale(1.005);
}

.home_subheading_contents{
    width: var(--section-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:95px;
    margin-top: 35px;
}
.home_subheading_contents .top_items{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    flex-direction: column;
    gap:15px;
}
.home_subheading_paragraph{
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-4);
    width: 100%;
    text-align: center;
    max-width: 465px;
}

.hiwc_card_array{
    display: flex;
    align-items: flex-start;
    justify-content: center;   
    gap:var(--hiwc-card-array-gap);
}

@media only screen and (min-width:480px){
    .mobile_subheading_arrow{
        display: none;
        position: absolute;
        align-self: center;
        right: 0px;
    }
}

@media only screen and (max-width:480px){

    .home_subheading_parent{
       
       
    }
    .subheading_arrow{
        display: none;
    }
    .mobile_subheading_arrow{
        display: visible;
        position: absolute;
        align-self: center;
        right: 0px;
    }
    .home_subheading_contents{

    }
    .home_subheading_contents .top_items{
   
    }
    .home_subheading_paragraph{
        width: 333.63px;
        height: 95.43px;
    }
    
    .hiwc_card_array{
        flex-direction: column;
        gap: 24px;
      
    }
    
    

}