.loading_overlay_parent_default{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: -70;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading_overlay_parent{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 70;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading_header{

    width: 564px;

    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);

    color: var(--accent-color-1);
    color:linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
    width: 100%;
    text-align: center;
   
}

.loading_header span, .suggestions_header span{
    color: var(--accent-color-2); 
    width: 300px;
}

.loading_hero_contents .lower_items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    
}
.loading_top_items{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.loading_time_parent{
    width: 564px;

    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--subheading-text-size);

    color: var(--accent-color-1);
    color:linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    animation: gradient 15s ease infinite;
    width: 100%;
    text-align: center;
}