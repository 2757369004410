.footer_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color-1);
    width: 100%;
    height: 970px;
    overflow: hidden;
    z-index: 2;
    position: relative;
    overflow: hidden;
}

.footer_left_shapes{
    position: absolute;
    left:305px;
    align-self: flex-end;
}

.footer_top_right_shapes{
    position: absolute;
    right:195px;
    align-self: flex-start; 
    margin-top: -115px;
    z-index: 0;
    overflow: hidden;
    clip-path: inset(115px 0px 0px 0px);
    pointer-events: none;
}
.footer_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: var(--section-width);
    height: 763.99951171875px;
    gap: 35px;
    z-index: 2;
}

.footer_contents >h1{
    text-align: left;
    font-size: 32px; 
    width: 429px;
    font-family: "LufgaSemiBold";
    color: var(--main);
    line-height: 45.68px;
    align-self: flex-start;
  
}

.text_icon_box_row{
    align-self: flex-start;
    display: flex;
    gap:98px;
}

.footer_lower_row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;  
}
.footer_lower_row .center_items{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 62px;
}
.footer_lower_row .right_items{
    display: flex;
    align-items: flex-start;

    height: 100%;
}
.footer_lower_row .right_items h1{
    font-family: 'Lufga';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 147px;
    height: 52px;
    /* Theme_Main_Gradient */
    
    background: var(--theme-main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent ;  
}

.list_item_icons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;
    font-size: 24px;
}
.footer_icon{
    transition: var(--theme-transition);
    cursor: pointer;
    color: white;
}
.list_item_icons .footer_icon:hover{
    color: var(--accent-color-2);
}
.list_item{
    list-style: none;
}
.list_item a {
    color: white;
    transition: var(--theme-transition);
}

.list_item a:hover{
    color: var(--accent-color-3);
}

@media only screen and (max-width:480px){


    .footer_parent{
      

    }
    
    .footer_left_shapes{

    }
    
    .footer_top_right_shapes{
        right: -285px;
        margin-top: -375px;
    }
    .footer_contents{
        width:338px;
    }
    
    .footer_contents >h1{

      
    }
    
    .text_icon_box_row{
        flex-wrap: wrap;
        gap: 29.18px;
    }
    
    .footer_lower_row{
        width: 100%;
        justify-content: space-between;
        gap:5%;
    }

    .footer_lower_row .left_items{
        align-self: flex-start!important;
    }
    .footer_lower_row .left_items .footer_list_ul{
        width:110px;
        list-style: none;
    }
    .footer_lower_row .center_items{
        flex-wrap: wrap;
        gap: 25px;
        justify-content: space-between;
    }
    .footer_lower_row .right_items{
        display: none;
    }
    .footer_lower_row .right_items h1{


    }
    
    .list_item_icons{

    }
    


}