
.hiwc_main{
    width: var(--hiwc-main-width);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--hiwc-main-gap);
}
.hiwc_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    height:  var(--hiwc-parent-height);
    width: var(--hiwc-parent-width);
    background: #FFFFFF;
    box-shadow: 0px 4.15205px 29.0643px rgba(0, 0, 0, 0.05);
    border-radius: 6.64327px;    
}

.hiwc_text{
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-4);
    width: 100%;
    text-align: center;
}


@media only screen and (max-width:480px){
    .hiwc_main{
        display: none;
    }
}