.plan_toggle_parent{
    display: flex;
    align-items: center;
    justify-content: center;

    background: #FFFFFF;
    height: 41px;
    width: 213px;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
}
.plan_toggle_contents{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(238, 29, 72, 0.05);
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;    
    width: 90%;
    transition: var(--theme-transition);
}
.monthly_toggle{
    padding: 5.37931px 13.4483px;
    gap: 13.45px;
    border:0px solid white;
    background: #EE1D48;
    box-shadow: 0px 0px 15px rgba(238, 29, 72, 0.65);
    border-radius: 10.7586px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: 16px;
    cursor: pointer;
    transition: var(--theme-transition);
}

.annual_toggle{
    padding: 3.17241px 7.93103px;
    gap: 7.93px;
    font-size: var(--smallest-text-size); 
    font-family: "LufgaRegular";
    background: #FF823A;
    opacity: 0.5;
    border-radius: 6.34483px;
    border: 0px solid white;
    color:white;
    cursor: pointer;
    transition: var(--theme-transition);

}
.annual_toggle_active {
    padding: 5.37931px 13.4483px;
    gap: 13.45px;
    border:0px solid white;
    background: #FF823A;
    box-shadow: 0px 0px 15px rgba(238, 106, 29, 0.473);
    border-radius: 10.7586px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: 16px;
    cursor: pointer;
    transition: var(--theme-transition);
    opacity: 1;
    transform: scale(1.1);

}

.annual_toggle:hover {

    opacity: 1;

}

.monthly_toggle_active{
    padding: 3.17241px 7.93103px;
    gap: 7.93px;
    font-size: var(--smallest-text-size); 
    font-family: "LufgaRegular";
    background: #EE1D48;
    box-shadow: 0px 0px 15px rgba(238, 29, 72, 0.65);
    opacity: 0.5;
    border-radius: 6.34483px;
    border: 0px solid white;
    color:white;
    cursor: pointer;
    transition: var(--theme-transition);
}

.monthly_toggle_annual_hovered{
    padding: 5.37931px 13.4483px;
    gap: 13.45px;
    border:0px solid white;
    background: #EE1D48;
    box-shadow: 0px 0px 15px rgba(238, 29, 72, 0.65);
    border-radius: 10.7586px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: var(--larger-paragraph-size);
    cursor: pointer;
    transition: var(--theme-transition);
    opacity: .35;
}

.monthly_toggle_active:hover{
    padding: 3.17241px 7.93103px;
    gap: 7.93px;
    font-size: var(--smallest-text-size); 
    font-family: "LufgaRegular";
    background: #EE1D48;
    box-shadow: 0px 0px 15px rgba(238, 29, 72, 0.65);
    opacity: 1;
    border-radius: 6.34483px;
    border: 0px solid white;
    color:white;
    cursor: pointer;
    transition: var(--theme-transition);
}

.monthly_toggle_active:hover ~ *{
    opacity: .35;
}


.monthly_toggle:hover{

    transform: scale(1.1);
}
/* 
@media only screen and (max-width:480px){
    .plan_toggle_parent{
        display: flex;
        align-items: center;
        justify-content: center;
    
        background: #FFFFFF;
        height: 29.58px;
        width: 153.70px;
        border-radius: 4.285px;
        
        box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
        border-radius: 5.93517px;
    }
    .plan_toggle_contents{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(238, 29, 72, 0.05);
        box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
        border-radius: 5.93517px;    
        height: 23.813186645507812px;
        width: 85%;
        border-radius: 4.282891273498535px;
        padding: 0px 5px 0px 5px;
        
    }
    .monthly_toggle{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 3.88177px 9.70443px;
     
        
     
 
        
        
       
        box-shadow: 0px 0px 10.8242px rgba(238, 29, 72, 0.65);
        border-radius: 7.76355px;
        cursor: pointer;
        transition: var(--theme-transition);
    
    
    }


    .annual_toggle{
        padding: 3.17241px 7.93103px;
        gap: 7.93px;
        font-size: var(--smallest-text-siz); 
        font-family: "LufgaRegular";
        background: #FF823A;
        opacity: 0.5;
        border-radius: 6.34483px;
        border: 0px solid white;
        color:white;
    
        height: 16.578502655029297px;
        width: 43.44625473022461px;
        border-radius: 4.578502178192139px;
        padding: 2px 6px 2px 6px;
        font-size: 9.157px;
        line-height: 12px;
    }

} */