.decision_suggestion_box_parent{
    display: flex;
    align-items: center;
    display: grid;
    height: 61px;
    width: 263px;
    border-radius: 5.655212879180908px;
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;    
    font-family: "LufgaSemiBold";
    color: var(--accent-color-4);
    text-overflow: ellipsis;
    opacity: .75;
    cursor: pointer;
    transition:var(--theme-transition);
    position: relative;
}

.suggestion_text{

    align-self: center;
    justify-self: center;
    text-overflow: ellipsis;
    max-width: 82%;
}
.suggestion_icon_box{
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    margin-left: 0px;
    background-color: var(--accent-color-2);
    color: white;
    padding: 5.2px 4.55px;
    gap: 2.6px;
    position: absolute;
    border-radius: 5.2px 0px;
}

.decision_suggestion_box_parent:hover{
    opacity: 1;
    scale: 1.02;
}