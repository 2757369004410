.accordion_parent{
    width: 100%;
    max-width: 700px;
    transition: all 0.35s ease-in-out;
    

    
   
}

.item{
    margin-bottom: 10px;
    padding: 25px 20px;
    background-color: rgb(250, 197, 197);
    transition: all 0.35s ease-in-out;
}

.item {

    width: 100%;
    border-radius: 3.86px;
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;
    transition: all 0.35s ease-in-out;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 5%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    


}
.item:hover .accordion{
    color: var(--accent-color-2);
}

.accordion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: "LufgaSemiBold";
    font-size:var(--subheading-text-size);
    transition: all 0.35s ease-in-out;
    width: 100%;
    background-color: transparent;
    border: 0px solid white;
    color: var(--accent-color-1);
    
    
}


.content {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: all 0.35s ease;
    font-family: "LufgaRegular";
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
    transition: all 0.35s ease-in-out;
    
}



.content_show {

    transition: all 0.35s ease-in-out;
    
    
}

.panel {
    padding: 0px 18px;
    background-color: white;
    max-height: 0px;
    width: 90%;
    overflow: hidden;
    transition: all 0.35s ease-in-out;
  }

 .panel p {
    position: relative;
    transition: all 0.35s ease-in-out;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    font-family: "LufgaRegular";
    color: var(--accent-color-1);
    line-height: 25px;
}


@media only screen and (max-width:1200px){

 
    .accordion_parent{
        display: flex;
        width: 95%;
        align-items: center;
        flex-direction: column;
        transition: all 0.35s ease-in-out;
        
    
        
       
    }
    .accordion_parent h2{
        text-align: left;
        font-size: var(--subheading-text-size);
    }
    
.item {




}

}