.subscription_toggle_text{
    font-size: var(   --regular-text-size); 
    font-family: "LufgaRegular";
    color: var(--accent-color-1);
    text-align: center; 
}

.subscription_toggle_text span{
    font-size: var(   --regular-text-size); 
    font-family: "LufgaBlack";
    color: var(--accent-color-2);
    text-align: center; 
}

.subscription_toggle_text_orange{
    font-size: var(   --regular-text-size); 
    font-family: "LufgaRegular";
    color: var(--accent-color-1);
    text-align: center; 
}
.subscription_toggle_text_orange span{
    font-size: var(   --regular-text-size); 
    font-family: "LufgaBlack";
    color: var(--accent-color-3);
    text-align: center; 
}