.arrow_text_container{

    display: flex;
    width: 575px;
    height: 385px;
}


.arrow_text_container .accent_text{
    font-family: "HeyAugust";
    color: var(--accent-color-3);
    font-style: normal;
    font-weight: 400;
    font-size: 27.4826px;
    line-height: 32px;
    text-align: center;
    margin-left: 255px;
    margin-top: 35px;
    position: absolute;
    color: #FF823A;
    transform: rotate(9.73deg);
}

/* .arrow_element > * {
    position: absolute;

} */
