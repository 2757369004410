.nav_button,
.home_button_style
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 18px;
    gap: 10px;
    background: transparent;
    border-radius: 16.0199px;
    border: 0px solid white;
    color:var(--accent-color-1);
    font-family: "LufgaBlack";
    font-size: var(--smallest-text-size);
    cursor: pointer;
    transition: var(--theme-transition);
}

.nav_button .button_icon{
    width:24px;
    height:24px;

}

.home_button_style:hover,
.nav_button:hover{


  
    background: #FFB8C6;
    color: var(--accent-color-4);
}

.home_button_style{
    background: #FFB8C6;
    color: var(--accent-color-2);
}
