

.subscription_card_mobile_parent{
    height: 121.04px;
    width: 352.73px;
    border-radius: 3.86px;
  
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;   
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    overflow: hidden;
}

.subscription_card_mobile_parent_hide{
    height: 121.04px;
    width: 352.73px;
    border-radius: 3.86px;  
    background: #FFFFFF;
    box-shadow: 0px 3.53451px 24.7416px rgba(0, 0, 0, 0.05);
    border-radius: 5.65521px;   
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    display: none;
}

.faded_mobile_particles{
    position: absolute;
    right: 16px;
    bottom: 5px;
}
.subscription_card_mobile_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height:80.6943654%;
    width:77.6676588%;
    gap:15px;
    z-index: 2;
}
.subscription_card_mobile_contents .top_items{
    width: 192.47px;
    height: 138.05px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
}

.subscription_card_mobile_contents .triangle_background{
    width: 50%;
    margin-left: -15%;
    height: auto;
    z-index: -1;
    opacity: .3;
    position: absolute;
    pointer-events: none;transform: rotateY(30deg);
}

.mobile_card_heading{
    font-size: var(--heading-text-size); 
    font-family: "LufgaBlack";
    text-align: left;
}
.mobile_card_price{
    font-size: 20.8254px;
    line-height: 27px;
    font-family: "LufgaSemiBold";
    color: var(--accent-color-1);
    text-align: left;
}

.mobile_card_price span{
    font-size: 20.8254px;
    line-height: 27px;
    font-family: "LufgaSemiBold";
    color: var(--accent-color-4);
    text-align: center;
}


.mobile_plan_description{
    font-size: 9.7619px;
    line-height: 13px;

    font-family: "LufgaRegular";   
    color: var(--accent-color-4);
    text-align: left;
}

.subscription_card_mobile_contents .bottom_items{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap:5px;
}
.subscription_details_mobile_list{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap:5px;
}
.subscription_details_mobile_list li{
    
    width: 131.46px;


    font-size: 10.4127px;
    line-height: 14px;
    font-family: "LufgaRegular";
    color: var(--accent-color-4);
    text-align: left;
}

.subscription_card_mobile_contents .bottom_items .main_button_no_icon{
    font-size: 10.4127px;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.6px;
    height: 24.41269874572754px;
    width: 89.15872955322266px;
    border-radius: 5.2063493728637695px;
    padding: 5px 5px 5px 5px;


}

@media only screen and (min-width:480px){
    .subscription_card_mobile_parent{
        display: none;
    }
}