body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "LufgaRegular";
  src: local("LufgaRegular"),
   url("./fonts/LufgaRegular.ttf") format("truetype");
  font-weight: bold;
  }

  @font-face {
    font-family: "LufgaBlack";
    src: local("LufgaBlack"),
     url("./fonts/LufgaBlack.ttf") format("truetype");
    
    }

    @font-face {
      font-family: "HeyAugust";
      src: local("HeyAugust"),
       url("./fonts/HeyAugust.ttf") format("truetype");
      
      }    
@font-face {
  font-family: "LufgaBold";
  src: local("LufgaBold"),
    url("./fonts/LufgaBold.ttf") format("truetype");
  font-weight: bold;
  }

  @font-face {
    font-family: "LufgaSemiBold";
    src: local("LufgaSemiBold"),
      url("./fonts/LufgaSemiBold.ttf") format("truetype");
    font-weight: bold;
    }
  