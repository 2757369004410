.faq_hero_parent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 1900px;
    position: relative;
    overflow-x: hidden;
    width: 100vw;
    padding-top: 135px;
}
.faq_hero_contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 80px;
    margin-top: 0px;
    
}
.faq_header{

    width: 564px;

    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);

    color: var(--accent-color-1);
    width: 100%;
    text-align: center;
   
}

.faq_header span, .suggestions_header span{
    color: var(--accent-color-2); 
}

.faq_hero_contents .lower_items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.faq_sugggestions_parent{
    display:flex;
height: 900px;
}

/* .suggestions_header{
    align-self: flex-start;
    width: 564px;
    height: 52px;
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: left;
    max-width: 465px;   
} */
@media only screen and (max-width:1200px){

    .faq_sugggestions_parent {
        display: flex;
        width: 75%;
        align-items: center;
        height: auto;
      
    
    }
   .faq_hero_contents .lower_items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 1200px;
    }
}

@media only screen and (max-width:480px){

    .faq_hero_parent {

        padding-top: 35px;
    }
    .faq_sugggestions_parent{
        display: flex;
        flex-direction: column;
      
    }

   .faq_hero_contents .lower_items{
        display: flex;
        justify-content: flex-start;
 
    }

    .faq_hero_contents {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin-top: 135px;
    }
    .faq_header {
        width: 70vw;

        font-size: var(--heading-text-size);

        line-height: 35px;
    } 
}