.email_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 507px;
    height: 76px;
    background: rgba(255, 248, 248, 0.123);
    box-shadow: var(--theme-shadow-1);
    border-radius: 8px;  
     
}
.email_contents_parent{
    display: flex;
  
    align-items: center;
    justify-content: space-between;
    width: 96.6469428%;
    height: 75%;
    border: 0px solid white;
    background: #FFFFFF;
    border-radius: 8px;
}

.text_input{
    width: 70%;
    height: 100%;
    padding: 0px 8% 0px 8%;
    border: 0px solid white;

    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: var(--accent-color-2);
    font-family: "LufgaBold";
    font-size: var(--subheading-text-size);
}

.text_input ~ .button_parent_container{
 
    margin-right: 3%;
   
}
.text_input {
    outline: none;
    border:none;
    transition: var(--theme-transition)
}
.text_input:focus{
    opacity:0.75;
}

/* .button_parent_container{
    min-width: fit-content;
    padding: 0px 3% 0px 3%;

} */