.decision_button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 7px;
    gap: 4px;
    
    /* Accent_Color_2 */
    background: var(--accent-color-2);
    border-radius: 8px 0px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: var(--smallest-text-size);
    cursor: pointer;
    transition: var(--theme-transition);
}

.decision_button_no_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 21px 8px 21px;
    gap: 4px;
    /* Accent_Color_2 */
    background: var(--accent-color-2);
    border-radius: 8px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: var(--smallest-text-size);
    cursor: pointer;
    transition: var(--theme-transition);
}

.decision_button .button_icon{
    width:24px;
    height:24px;

}
.decision_button:hover{
    background-color: var(--accent-color-3);
}

.decision_button_no_icon:hover{
    background-color: var(--accent-color-3);
}

@media only screen and (max-width:480px){
    .decision_button{
        height: 23.617023468017578px;
        width: 53.797874450683594px;
        left: 233.18359375px;
        top: 24.20745849609375px;
        border-radius: [object Object]px;
        padding: 5px 4px 5px 4px;
        font-size: 8.26596px;
        line-height: 11px;        
    }
    
    .decision_button_no_icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 21px 8px 21px;
        gap: 4px;
        /* Accent_Color_2 */
        background: var(--accent-color-2);
        border-radius: 8px;
        border: 0px solid white;
        color:white;
        font-family: "LufgaRegular";
        font-size: var(--smallest-text-size);
        cursor: pointer;
        transition: var(--theme-transition);
    }
    
    .decision_button .button_icon{
        width:14.17px;
        height:14.17px;
    
    }

}