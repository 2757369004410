.expandable_decision_search_box_parent{
    min-height: 200.03668212890625px;
    width: 307.4528503417969px;
    /* width: 541px; */

    display: flex;
    filter: drop-shadow(0px 4px 75px rgba(238, 29, 72, 0.25));
    z-index: 52;
}


.expandable_decision_search_box_shape_tl{
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 108px;
    width: 90px;
  
    border-radius: 3.5042784214019775px;
    
    margin-left: -20px;
    margin-top: -25px;
   

    position: absolute;

}

.expandable_decision_search_box_shape_br {
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 108px;
    width: 90px;
    right: -15px;
    bottom: -15px;
    border-radius: 3.5042784214019775px;
    position: absolute;
}
.expandable_decision_search_box_background {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: max-content;
    width: 307.4528503417969px;
    background: #FFF8F8;
    box-shadow: 0px 7.41071px 51.875px rgba(0, 0, 0, 0.05);
    border-radius: 0px 22.2321px;
    z-index: 1;
}

.expandable_decision_search_box_foreground {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;
    min-height: 188px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4.92689px 34.4883px rgba(0, 0, 0, 0.05);
    border-radius: 0px 14.7807px;
    padding: 15px;
}
.expandable_decision_search_box_foreground_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    width:85%;
    height: 85%;
}
.expandable_decision_search_input{
    width: 100%;
    height: 100%;
    font-family: 'LufgaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    border: 0px solid white;
    color: var(--accent-color-1);
    
}
.textarea{
    width: 100%;
    height: 100%;
    font-family: 'LufgaRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    border: 0px solid white;
    color: var(--accent-color-1);
    
}

/* To remove black border on focus */
.textarea:focus {
    border-style: none; 
    border-color: Transparent; 
    overflow: auto;  
    outline: none;
}

.textarea::-moz-focus-inner{
    border: 0px solid white;
}
.default_text{
    position: absolute;
    font-family: 'LufgaRegular';
}


@media only screen and (min-width:1050px){
    .expandable_decision_search_box_parent{
        min-height: 200.03668212890625px;
        width: 600px;
        /* width: 541px; */
    
        display: flex;
        filter: drop-shadow(0px 4px 75px rgba(238, 29, 72, 0.25));
    }
    .expandable_decision_search_box_background {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        height: max-content;
        width: 100%;
        background: #FFF8F8;
        box-shadow: 0px 7.41071px 51.875px rgba(0, 0, 0, 0.05);
        border-radius: 0px 22.2321px;
        z-index: 1;
    }
    
    
}