


.accent_button{
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px 8px 7px;
    gap: 4px;
    
    width: 92px;
    height: 40px;
    
    /* Accent_Color_2 */
    background: var(--accent-color-2);
    background: transparent;
    border-radius: 8px;
    border: 2px solid var(--accent-color-2);
    color:var(--accent-color-2);;
    font-family: "LufgaRegular";
    font-size: var(--smallest-text-size);
    cursor: pointer;
    transition: var(--theme-transition);
    z-index: 45;
    position: relative;
}

.accent_button_no_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 21px 8px 21px;
    gap: 4px;
    /* Accent_Color_2 */
    background: var(--accent-color-2);
    border-radius: 8px;
    border: 0px solid white;
    color:white;
    font-family: "LufgaRegular";
    font-size: var(--smallest-text-size);
    cursor: pointer;
    transition: var(--theme-transition);
}

.accent_button .button_icon{
    width:24px;
    height:24px;

}
.accent_button:hover{
    background-color: var(--accent-color-2);
    color: white;
}

.accent_button_no_icon:hover{
    background-color: var(--accent-color-3);
}

@media only screen and (max-width:480px){
    /* .accent_button{
        height: 21.66px;
      
        left: 114.66px;
        top: 163.60px;
        border-radius: 4.33px;
        padding: 4px 11px 4px 4px;

        font-size: 7.58px;
        line-height: 10px;

    }
 
    .accent_button .button_icon{
        width: 13px;
        height: 13px;   
    
    }
    */
    
}