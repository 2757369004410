.main_close_button_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53.89px;
    width: 53.89px;
    background: linear-gradient(30.16deg, rgba(238, 29, 72, 0.05) 34.16%, rgba(255, 130, 58, 0.05) 112.52%);
    border-radius: 16.8429px;  
    z-index: 52;
    border: 0px solid white;

    cursor: pointer;
}


.main_close_button_bars_list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 7.5%;
    height:90%;
    width: 90%;
}


.main_close_button_bars_list .top_bar,
.main_close_button_bars_list .middle_bar,
.main_close_button_bars_list .bottom_bar,
.main_close_button_bars_list .top_bar_clicked,
.main_close_button_bars_list .middle_bar_clicked,
.main_close_button_bars_list .bottom_bar_clicked

{
    width: 29.47px;
    height: 3.68px;
    background: var(--theme-main-gradient);
    border-radius: 3px;
    position: absolute;
    transition: var(--theme-transition);
}
.main_close_button_bars_list .top_bar{
    margin-top:-25%;
}
.main_close_button_bars_list .bottom_bar{
    margin-top:25%;
}
.main_close_button_bars_list .top_bar_clicked{
    transform: rotateZ(45deg);
    transition: var(--theme-transition);
}
.main_close_button_bars_list .middle_bar_clicked{
    opacity: 0;
    transform: scaleX(0);
    transition: var(--theme-transition);
}
.main_close_button_bars_list .bottom_bar_clicked{
    transform: rotateZ(-45deg);
    transition: var(--theme-transition);
}

