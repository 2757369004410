.footer_banner_parent{
    width: 100%;
    height: 213px;
    background: var(--theme-main-gradient);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_banner_contents{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80%;
    width: 80%;
}

.footer_banner_contents h1 {
    text-align: left;
    font-size: var(--large-subheading-text-size);
    width: 429px;
    font-family: "LufgaSemiBold";
    color: var(--main);
   
    align-self: flex-start;
}

.footer_banner_contents .left_items{

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    gap:1px;
    margin-top: 15px;
}


.footer_banner_contents .right_items{

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
 
   
}

.footer_banner_subtext{


    font-family: 'LufgaSemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    display: flex;
    align-items: center;
    color: #0B1945;

}


@media only screen and (max-width:480px){

    .footer_banner_parent{
        height: 82.31px;
        width: 339.26px;

        border-radius: 4.78755px;
       
    }
    .footer_banner_contents .left_items{
        margin-top: 0px;
    }
    
    .footer_banner_contents h1 {
        font-size: 11.1709px;
        line-height: 15px;
        width: 100%;
        
    }
    .footer_banner_subtext{

        font-size: 11.1709px;
        line-height: 15px;
    }

.footer_banner_scribble{
    height: 3.86px;
    width: 84.13px;
    

}
}