.demo_video_overlay_parent {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: -2;
    background-color: bisque;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.demo_video_overlay_parent_displayed {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 900;
    background-color: bisque;
    display: flex;
    align-items: center;
    justify-content: center;
    display: visible;
}

.demo_video_overlay_parent div {
    position: absolute;
    /* padding-bottom: 56.25%; */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.demo_video_overlay_parent div iframe {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    width: auto;
    height: auto;
    width: 1080px;
    height: 720px;

}



.demo_video_overlay_parent_displayed .main_close_button_parent{
    position: absolute;
    top: 15%;
    right: 15%;
}
element.style {
    /* position: absolute; */
    /* width: 50%; */
    /* height: 50%; */
}