.decision_hero_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}
.decision_hero_contents{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 28px;
}


.model_details_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    gap: 8px;
    height: 50px;
    
    
}

.model_details_container_top_items{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
   
}

.model_details_container_top_items .mode_header{
    font-style: normal;

    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 400;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
}

.model_details_container_top_items .mode_header span{
    color: var(--accent-color-2); 
    font-family: "LufgaBlack";
}


.model_details_container_top_items .mode_header .secondary_span{
    color: var(--accent-color-3); 
    font-family: "LufgaBlack";
}

.model_details_container_lower_items{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.model_details_container_lower_items .mode_subtext{
    font-style: normal;

    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 400;
    font-size: 14px;
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-3);
}


.decision_header{

    width: 564px;
    height: 52px;
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--heading-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: center;
    max-width: 465px;   
}

.decision_header span, .suggestions_header span{
    color: var(--accent-color-2); 
}

.lower_items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.decision_sugggestions_parent{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
}

.suggestions_header{
    align-self: flex-start;
    width: 564px;
    height: 52px;
    left: 438px;
    top: 361px;
    font-style: normal;
    font-weight: 900;
    color: #0B1945;
    font-family: "LufgaRegular";
    font-weight: 700;
    font-size: var(--regular-text-size);
    line-height: var(--regular-text-line-height);
    color: var(--accent-color-1);
    width: 100%;
    text-align: left;
    max-width: 465px;   
}

@media only screen and (max-width:480px){
    .decision_hero_contents {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 28px;
        margin-top: 5vh;
    }
    .decision_sugggestions_parent{
        display: flex;
        flex-direction: column;
    }

    
}