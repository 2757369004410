
.hiwcm_main{
    display: none;
}


.hiwcm_main_alternate{
    display: none;
}




@media only screen and (max-width:480px){
    .hiwcm_main{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 339.36px;
        height: 101px;
        background: #FFFFFF;
        box-shadow: 0px 4.15205px 29.0643px rgba(0, 0, 0, 0.05);
        border-radius: 6.64327px;    
        gap:3%;
    }
    
    .hiwcm_main_alternate{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        width: 339.36px;
        height: 101px;
        background: #FFFFFF;
        box-shadow: 0px 4.15205px 29.0643px rgba(0, 0, 0, 0.05);
        border-radius: 6.64327px;    
        gap:3%;
    }
    
    
    
    .hiwcm_parent{
        display: flex;
        align-items: center;
        justify-content: center;
    
    }
    
    .hiwcm_text{
        font-family: "LufgaRegular";
        font-weight: 700;
        font-size: 13.4667px;
        line-height: 18px;
        color: var(--accent-color-4);
        width: 70%;
        text-align: left;
    
    }
    
    .card_icon{
        height: 72.71998596191406px;
        width: 72.71998596191406px;
    
    
    }
    
}
