.footer_tib_parent{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 278px;
    height: 130px;  
    gap: 12px;
}
.footer_tib_parent .top_items {
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    flex-direction: row;
    gap: 15px;
}
.footer_tib_parent .top_items .icon_box{
    height: 48px;
    width: 48px;

    border-radius: 15px;
    background: linear-gradient(30.16deg, rgba(238, 29, 72, 0.5) 34.16%, rgba(255, 130, 58, 0.5) 112.52%);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.icon_box .icon{
    font-size: 35px;
}

.footer_tib_parent .top_items h1{
    text-align: left;
    font-size: 32px; 
    width: 429px;
    font-family: "LufgaSemiBold";
    color: var(--main);
    line-height: 45.68px;
    font-size: var(--subheading-text-size);
     
}

.icon_box_paragraph {
    text-align: left;
    height: 70px;
    width: 278px;
    font-family: "LufgaRegular";
    color: var(--main);
    line-height: var(--regular-text-line-height);
    font-size: var(--regular-text-size);
     
}


@media only screen and (max-width:480px){
    .footer_tib_parent{
        height: 71.69px;
        width: 153.31px;
        left: 29.20px;
        top: 254.91px;
        gap: 6.62px;
    }

    .footer_tib_parent .top_items .icon_box{
        height: 26.47px;
        width: 26.47px;
        border-radius: 8.27261px;
    }
    
    .icon_box .icon{
        font-size: 19.3px;
    }
    
    .footer_tib_parent .top_items h1{

        font-size: 13.79px;
        line-height: 12px;
    }
    
    .icon_box_paragraph {

        height: 38.605506896972656px;
        width: 153.31900024414062px;
        left: 0px;
        top: 33.090087890625px;
        font-size: 8.82412px;
        line-height: 12px;
            
    }

}