.subscription_card_variant_mobile_parent{
    position: relative;
    transform: scale(1.04);
    filter: drop-shadow(0px 2.60317px 48.8095px rgba(238, 29, 72, 0.25));
}

.subscription_card_variant_mobile_parent .mobile_background_shape_top_left{

    z-index: -1;
    position: absolute;
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 111.28px;
    width: 126.90px;
    left: -2%;
    border-radius: 3.86px;
    top: -7%;


}



.subscription_card_variant_mobile_parent .mobile_background_shape_bottom_right{
    right: -2.5%;

    top: 15%;

    z-index: -1;

    position: absolute;
    background: #EE1D48;
    box-shadow: 0px 3.70948px 25.9664px rgba(0, 0, 0, 0.05);
    border-radius: 5.93517px;
    height: 111.28px;
    width: 126.90px;
    border-radius: 3.86px;
}

@media only screen and (min-width:480px){
    .subscription_card_variant_mobile_parent{
        display: none;
    }
}