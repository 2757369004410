.contact_overlay_parent_default{
    display: none;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    width: 100vw;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -50;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

.contact_overlay_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    width: 100vw;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 60;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.contact_overlay_contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 700px;
}
.contact_overlay_parent .main_close_button_parent{
top: 175px;
right: 25%;
position: absolute;
}
.contact_content_details{

}


.contact_content_details .email_data{
    color: #0B1945;
    font-family: "LufgaBlack";
    font-weight: 700;
    font-size: var(--subheading-text-size);
    transition: var(--theme-transition)
}

.contact_content_details .email_data:hover{
    opacity: .5;
}